// BUTTONS
.waw-btn, .waw-btn.mat-button, .waw-btn.mat-stroked-button, .waw-btn.mat-flat-button {
    // buttons body settings
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 42px;
    padding: 10px 20px;
    background-color: $c-main;
    border: none;
    border-radius: 10px;
    color: $white;
    font-family: $ff-base;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    flex-shrink: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @include bp-max(md) {
        &:not(._not_change_on_mobile) {
            font-size: 14px;
            line-height: normal;
        }
    }

    @include bp-max(sm) {
        &:not(._not_change_on_mobile) {
            height: 32px;
            min-width: 80px;
            padding: 8px 15px;
            border-radius: 6px;
            font-size: 12px;
            line-height: normal;
        }
    }

    // buttons color settings
    &._primary {
        background-color: $c-main;
        box-shadow: 0px 4px 19px 0px rgba(37, 110, 255, 0.18);
        &:hover, &:focus {
            background-color: #80cdbe;
        }
    }

    &._second, &._second:disabled {
        border: 1px solid $c-main;
        background-color: $white;
        color: $c-main;
    }

    &._second:disabled:hover {
        background-color: $white;
    }

    &._danger {
        // border: 1px solid $c-red;
        background-color: $white;
        color: $c-red;
    }

    &._delete .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "\e931";
            margin-left: 3px;
            font-family: 'icomoon' !important;
            //   speak: never;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 19px;
        
            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include bp-max(sm) {
                margin-left: 10px;
                font-size: 16px;
            }
        }
    }

    &._edit {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 6px;
        font-size: 14px;
        line-height: 32px;

        .mat-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
    
            &::before {
                content: "\e918";
                font-family: 'icomoon' !important;
                //   speak: never;
                font-size: inherit;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: normal;
                
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        
        @include bp-max(sm) {
            width: 28px;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
        }
    }

    &:disabled {
        color: $white;
        background-color: $c-main;
        cursor: not-allowed;

        &:hover {
            background-color: $c-main;
            cursor: not-allowed;
        }
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
    }

    .icon-plus {
        margin-right: 14px;
        @include bp-max(sm) {
            margin-right: 12px;
        }
    }

    // &._primary--mob,
    // &._second--mob,
    // &._danger--mob {
    //     @include bp-max(sm) {
    //         min-width: 0;
    //         width: 45px;
    //         height: 45px;
    //         font-size: 1px;
    //         color: rgba(0, 0, 0, 0);

    //         &::before {
    //             font-family: 'icomoon';
    //             font-size: 24px;
    //             position: absolute;
    //         }
    //     }
    // }

    // &._primary--mob {
    //     @include bp-max(sm) {
    //         &::before {
    //             content: "\e92b";
    //             color: $white;
    //             left: 11px;
    //             top: 5px;
    //         }
    //     }
    // }

    // &._second--mob {
    //     @include bp-max(sm) {
    //         &::before {
    //             content: "\e92a";
    //             color: $sky;
    //             left: 9.5px;
    //             top: 3.5px;
    //         }
    //     }
    // }

    // &._danger--mob {
    //     @include bp-max(sm) {
    //         &::before {
    //             content: "\e91d";
    //             color: $white;
    //             left: 14.5px;
    //             top: 8.5px;
    //             font-size: 18px;
    //         }
    //     }
    // }
}

.mat-button.mat-primary:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button.mat-primary:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-button.mat-warn:hover:not(.mat-button-disabled) .mat-button-focus-overlay, .mat-stroked-button.mat-warn:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
    opacity: 0.1 !important;
}
.mat-icon-button:hover .mat-button-focus-overlay {
    opacity: 0.04 !important;
}
.mat-primary.mat-icon-button:hover .mat-button-focus-overlay, .mat-warn.mat-icon-button:hover .mat-button-focus-overlay {
    opacity: 0.1 !important;
}

.waw-btn__icon {
	width: 42px !important;
	min-width: 42px !important;
	height: 42px !important;
	padding: 0 !important;
	border-radius: 10px !important;
	line-height: 42px !important;
    transition: all 0.2s ease-in-out;
    user-select: none;

	@include bp-max(md) {
        &._tablet {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
            line-height: 32px !important;
            border-radius: 50% !important;
            border: none !important;
        }
	}

	@include bp-max(sm) {
        width: 32px !important;
        min-width: 32px !important;
        height: 32px !important;
        line-height: 32px !important;

        &:not(._mobile):not(._tablet) {
	        border-radius: 8px !important;
        }

        &._mobile {
            border-radius: 50% !important;
            border: none !important;
        }
        
		.material-icons {
			font-size: 20px;
			line-height: 32px;
		}
	}

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

	&.primary {
		border: 1px solid $c-main;

        [class^="icon-"]:before, [class*=" icon-"]:before {
            color: $c-main;
            font-size: 16px;

            @include bp-max (sm) {
                font-size: 15px;
            }
        }
	}

	&.warn {
        border: 1px solid $c-red;

        &:disabled {
            border-color: rgba(0, 0, 0, 0.26);
        }
        
        [class^="icon-"]:before, [class*=" icon-"]:before {
            color: $c-red;
            font-size: 16px;

            @include bp-max (sm) {
                font-size: 15px;
            }
        }
	}
}

.waw-btn__add {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    line-height: 18px !important;
    border: 1px solid $c-main !important;
    border-radius: 6px !important;
    background: transparent !important;
    font-family: 'icomoon' !important;
  //   speak: never;
    font-size: 11px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before {
        content: "\e930";
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        font-size: 10px;
        transform: translate(-50%, -50%);
    }
}

.waw-btn__add-label {
    margin-left: 12px;
    color: $c-main;
    font-family: $ff-second;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.action {
    &-edit, &-delete {
        @include bp-max (sm) {
            width: 35px !important;
            height: 35px !important;
            line-height: 35px !important;
        }
    }

    &-edit [class^="icon-"]:before, [class*=" icon-"]:before, &-delete [class^="icon-"]:before, [class*=" icon-"]:before {
        font-size: 18px;

        @include bp-max (sm) {
            font-size: 15px;
        }
    }

    &-edit [class^="icon-"]:before, [class*=" icon-"]:before {
        color: $c-main;
    }

    &-delete [class^="icon-"]:before, [class*=" icon-"]:before {
        color: $c-red;
    }
}