// table
.table-wrapp {
    background: $white;
    padding: 20px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(51, 77, 110, 0.25);
    border-radius: 35px;

    table {
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
    }

    .cl-table-clients {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &._fl-column {
            @media screen and (max-width: 1468px) {
                flex-direction: column;
                align-items: center;

                .cl-table-clients__info {
                    margin-left: 0;
                }
            }
        }



        @include bp-max(sm) {
            flex-direction: row;

            .cl-table-clients__info {
                margin-left: 15px;
            }
        }

        .icon-pencil {
            &::before {
                transition: .2s all ease-in-out;
            }

            &:hover {
                &::before {
                    color: $sky;
                    transition: .2s all ease-in-out;
                }

            }
        }

        &__img {
            min-width: 50px;
            min-height: 50px;
            max-width: 50px;
            max-height: 50px;
            width: 50px;
            height: 50px;
            border: 1px solid #DFE0EB;
            box-sizing: border-box;
            border-radius: 50%;
            padding: 3px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        &__info {
            flex-grow: 1;
            text-align: left;
            margin-left: 15px;
        }
        &__status {
            color: $red;
        }
    }

    &._vertical {
        @media screen and (max-width: 600px) {
            .cl-table-clients__img {
                display: none;
            }

            td {
                text-align: right;
            }

            .cl-table-clients__info {
                text-align: right;
            }

            table {
                border: 0;
            }

            td .cl-table-clients {
                align-items: flex-end;
            }

            table caption {
                font-size: 1.3em;
            }

            table thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            table tr {
                display: block;
                margin-bottom: .625em;
                border-bottom: 3px solid #ddd;
            }

            table td {
                display: block;
                border-right: none;
                text-align: right;
                min-height: 56px;
            }

            table td::before {
                content: attr(data-label);
                float: left;
                font-weight: bold;
                text-transform: uppercase;
            }

            table td:last-child {
                border-bottom: 0;
            }
        }
    }


    th {
        padding: 15px 10px;
        text-align: center;
        border-right: 0.5px solid #979797;
        border-bottom: 0.5px solid #979797;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: $c-basic;


        &:last-child {
            border-right: none;
        }

        @include bp-max(md) {
            font-size: 12px;
        }
    }

    td {
        position: relative;
        cursor: pointer;
        padding: 15px 10px;
        text-align: center;
        vertical-align: middle;
        border-right: 0.5px solid #979797;
        border-bottom: 0.5px solid #979797;
        letter-spacing: 0.01em;
        word-break: break-word;
        font-size: 14px;
        line-height: 16px;
        color: $c-basic;

        @include bp-max(md) {
            font-size: 12px;
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
        }
    }

    tr {

        td:last-child,
        th:last-child {
            border-right: none;
            border-right: none;
        }

        @media (max-width:600px) {
            &:nth-child(even) {
                background: rgba(0, 0, 0, 0.04);
            }
        }

    }
}

.table-wrapp tbody tr {
    transition: .3s all ease-in-out;

}

@include bp-max(xl) {
    .xl-hide {
        display: none;
    }

    .xl-br-none {
        border-right: none !important;
    }
}

@include bp-max(sm) {
    .sm-hide {
        display: none;
    }

    .sm-br-none {
        border-right: none !important;
        position: relative;
    }

}

th {
    .material-icons {
        opacity: 0;
        transition: 0.3s all ease-in-out;
		margin-left: 10px;
    }
    &:hover {
        .material-icons {
            opacity: 1;
            transition: 0.3s all ease-in-out;
        }
    }
}


// new table
.table-container {
    width: 100%;
    margin-top: -12px;
    margin-bottom: -12px;

    .table {
        // .tablet-col2, .tablet-col3, .mobile-col3
        width: 100%;
        border-spacing: 0 12px;
        table-layout: fixed;

        thead {
            @include bp-max(sm) {
                display: none;
            }
    
            th {
                padding: 4px 8px;
                color: $c-text_dark-grey-2;
                font-family: $ff-second;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                user-select: none;
    
                &:first-child {
                    padding-left: 85px;
                }
    
                &:last-child {
                    width: calc(100px + 8px + 16px);
                    padding-right: 16px;
                }
    
                @include bp-max(md) {
                    padding: 4px;
                    font-size: 12px;
    
                    &:first-child {
                        padding-left: 54px;
                    }
        
                    &:last-child {
                        width: calc(85px + 5px + 13px);
                        padding-right: 13px;
                    }
                }
    
                &.sort {
                    cursor: pointer;

                    .table-header__sort {
                        display: none;
                        position: relative;
                        
                        .arrow {
                            position: absolute;
                            top: 50%;
                            opacity: 0.3;
                            margin: 0;
                            font-size: 22px;
                            user-select: none;
                            transition: 0.1s all ease-in-out;
                    
                            &-up {
                                transform: translate(0, calc(-50% - 4px));
                            }
                            
                            &-down {
                                transform: translate(0, calc(-50% + 4px));
                            }
                            
                            @include bp-max(md) {
                                font-size: 20px;
                                
                                &-up {
                                    transform: translate(0, calc(-50% - 3.5px));
                                }
                                
                                &-down {
                                    transform: translate(0, calc(-50% + 3.5px));
                                }
                            }
                        }
                        
                        &._sortActiveDown {
                            .arrow-down {
                                opacity: 1;
                            }

                            .arrow-up {
                                opacity: 0.3;
                            }
                        }
                    
                        &._sortActiveUp {
                            .arrow-up {
                                opacity: 1;
                            }

                            .arrow-down {
                                opacity: 0.3;
                            }
                        }
                    }
                }
            }
        }

        tbody {
            @include bp-max(sm) {
                display: grid;
                grid-template-columns: 1fr;
                grid-column-gap: 30px;
                grid-row-gap: 15px;
            }

            tr {
                border-radius: 10px;
                box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);
        
                @include bp-max(sm) {
                    background: white;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 15px;
                    grid-row-gap: 20px;
                    width: 100%;
                    padding: 10px 15px;
                    // border-radius: 14px;
                }
            }

            td {
                padding: 15px 8px;
                background: white;
                color: $c-black;
                font-family: $ff-base;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
        
                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    padding-left: 20px;
                }
        
                &:last-child {
                    width: calc(100px + 8px + 16px);
                    padding-right: 16px;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                .actions {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 10px;
                }
        
                @include bp-max(md) {
                    padding: 10px 5px;
                    font-size: 12px;
        
                    &:first-child {
                        padding-left: 10px;
                    }
        
                    &:last-child {
                        width: calc(85px + 5px + 13px);
                        padding-right: 13px;
                    }
                }
        
                
                @include bp-max(sm) {
                    width: 100% !important;
                    padding: 0 !important;
                    font-size: 10px !important;
                    border-radius: 0 !important;
        
                    &::before {
                        content: attr(data-label);
                        display: block;
                        padding-bottom: 10px !important;
                        color: $c-text_dark-grey-2;
                        font-family: $ff-second;
                        font-size: 12px !important;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: keep-all;
                        white-space: nowrap;
                    }

                    .actions {
                        gap: 8px;
                        margin-top: -10px;
                
                        .waw-btn__icon {
                            background-color: $c-dark_white;
                        }
                    }
                }
            }
        }
    }

    &.tablet-col2 {
        @include bp-max(md) {
            .table {
                thead {
                    display: none;
                }
                tbody {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-column-gap: 30px;
                    grid-row-gap: 15px;
                    
                    tr {
                        background: white;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-gap: 15px;
                        grid-row-gap: 20px;
                        grid-template-rows: min-content min-content auto;
                        width: 100%;
                        padding: 15px;
                    }
                    td {
                        width: 100% !important;
                        padding: 0 !important;
                        background: transparent;

                        &::before {
                            content: attr(data-label);
                            display: block;
                            padding-bottom: 15px;
                            color: $c-text_dark-grey-2;
                            font-family: $ff-second;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: keep-all;
                            white-space: nowrap;
                        }

                        .actions {
                            gap: 8px;
                            margin-top: -10px;
                    
                            .waw-btn__icon {
                                background-color: $c-dark_white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.tablet-col3 {
        @include bp-max(md) {
            .table {
                thead {
                    display: none;
                }
                tbody {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 30px;
                    grid-row-gap: 15px;

                    tr {
                        background: white;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-column-gap: 10px;
                        grid-row-gap: 20px;
                        grid-template-rows: min-content min-content auto;
                        padding: 10px 15px;
                    }

                    td {
                        width: 100%;
                        padding: 0 !important;
                        border-radius: 0 !important;
                        background: transparent;
                        font-size: 12px;

                        &::before {
                            content: attr(data-label);
                            display: block;
                            padding-bottom: 10px;
                            color: $c-text_dark-grey-2;
                            font-family: $ff-second;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: keep-all;
                            white-space: nowrap;
                        }

                        .actions {
                            gap: 8px;
                            margin-top: -10px;
                    
                            .waw-btn__icon {
                                background-color: $c-dark_white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.mobile-col3 {
        @include bp-max(sm) {
            .table {
                thead {
                    display: none;
                }
                tbody {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-column-gap: 30px;
                    grid-row-gap: 15px;

                    tr {
                        background: white;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-column-gap: 10px;
                        grid-row-gap: 20px;
                        grid-template-rows: min-content min-content auto;
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}

.table__user {
    display: flex;
    align-items: center;

    &__img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
    }

    &__info {
        margin-left: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        text-wrap: nowrap;

        &-desc {
            font-size: 80%;
        }

        &-deleted {
            color: $c-red;
        }
    }

    @include bp-max(md) {
        &__img {
            min-width: 34px;
            min-height: 34px;
            max-width: 34px;
            max-height: 34px;
            width: 34px;
            height: 34px;
        }

        &__info {
            margin-left: 10px;
        }
    }

    @include bp-max(sm) {
        &__img {
            min-width: 28px;
            min-height: 28px;
            max-width: 28px;
            max-height: 28px;
            width: 28px;
            height: 28px;
        }

        &__info-desc {
            font-size: 100%;
        }
    }
}

.note {
	overflow-x: auto; 
    
    &._limit {
        position: relative;
        overflow-y: hidden; 
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        
        &._more {
            overflow-x: hidden; 
            cursor: pointer;
        }
    
        &._all {
	        overflow-x: auto; 
            -webkit-line-clamp: unset;
        }

        & + .note-btn {
            margin-top: 4px;
            margin-left: -2px;
            padding: 2px;
            border: none;
            border-radius: 4px;
            background: transparent;
            color: inherit;
            font-family: $ff-base;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0px;
            text-align: center;
            transition: 0.1s all ease-in-out;
            cursor: pointer;

            &:active {
                background: #3434341e;
            }
        }
    
        @include bp-max(md) {
            -webkit-line-clamp: 3;

            & + .note-btn {
                font-size: 12px;
            }
        }
    }

	p {
		margin: 2px 0;
	}
}