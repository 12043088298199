.table-container.week {
    position: relative;
    overflow: auto; 

    .table {
        thead th {
            padding-bottom: 8px;
        }

        thead th:first-child {
            background-color: $c-background;
            height: 1px; 
            & > div {
                height: 100%; 
            }
        }

        thead th:last-child {
            background-color: $c-background;
        }

        tbody td.table__working-hours {
            &:not(._off) {
                cursor: pointer;

                & > span {
                    line-height: 1.5;
                    position: relative;
                    z-index: 1;
                }
            }

            &._off {
                background-color: #E9EBEF;
                border-right: 2px solid $white;

                &::before {
                    background-color: $white;
                }
            }

            &._add {
                padding: 0 !important;

                & > span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.1s ease-in-out;
                }
            }
        }

        @include bp-min(lg) {
            thead th:first-child, tbody td:first-child {
                position: sticky; 
                width: 230px;
                left: 0px;    
                z-index: 3;
            }
    
            thead th:not(:first-child):not(:last-child), tbody td:not(:first-child):not(:last-child) {
                width: 140px;
                // width: 120px;
            }
    
            thead th:last-child, tbody td:last-child {
                position: sticky; 
                width: 80px;
                right: 0px;    
                z-index: 3;
            }

            tbody td:first-child, tbody td:last-child {
                transform-style: preserve-3d;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 15px;
                    height: 100%;
                    background-color: $c-background;
                    transform: translateZ(-1px);
                }
            }

            tbody td:first-child {
                border-right: 1px solid #E9EBEF;

                &::before {
                    left: 0;
                }
            }

            tbody td:last-child {
                border-left: 1px solid #E9EBEF;

                &::before {
                    right: 0;
                }
            }

            tbody td:nth-last-child(2).table__working-hours {
                border-right: none !important;
            }

            tbody td.table__working-hours {
                font-weight: 500;
                border-right: 1px solid #E9EBEF;

                & > span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: keep-all;
                    white-space: nowrap;
                    display: block;
                }

                &._add {
                    height: 1px;

                    &:hover span {
                        opacity: 1;
                    }

                    span {
                        opacity: 0;
                    }
                }
            }

        }

        @include bp-min(xxl) {
            tbody td.table__working-hours {
                padding: 20px;
            }
        }

        @include bp-max(md) {
            tbody td {
                display: flex;
                flex-direction: column;

                &::before {
                    flex-shrink: 0;
                    z-index: 1;
                    position: relative;
                }

                &:first-child { 
                    overflow: hidden;

                    &::before {
                        padding-bottom: 26px !important;
                    }
                }

                .table__user {
                    height: 100%;
                }
            }

            tbody tr {
                grid-template-rows: min-content auto !important;

                > td.table__working-hours {
                    left: -5px;
                    width: calc(100% + 10px) !important;

                    &::before, &::after {
                        padding-left: 5px;
                    }

                    & > span {
                        margin-left: 5px;
                    }
                }
            }

            tbody td.table__working-hours {
                position: relative;

                &::before {
                    padding-bottom: 30px !important;
                    font-size: 10px !important;
                }

                &::after {
                    content: attr(data-day);
                    position: absolute;
                    top: 20px;
                    color: #999;
                    font-family: $ff-second;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    z-index: 1;
                }
            }

            tbody td.table__working-hours > span {
                height: 100%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 2px;
                overflow: hidden;
            }

            tbody td.table__working-hours._off > span {
                margin: 15px 5px;
            }

            tbody td.table__working-hours._add > span {
                justify-content: flex-start;
            }
        }
    }
}

.table-container.month {
    margin-top: 0;
    margin-bottom: 0;

    .table {
        border-spacing: 0px;
        
        thead th {
            padding-bottom: calc(4px + 12px);
            color: #999;
            font-weight: 400;
        }

        thead th:last-child, tbody td:last-child {
            width: auto !important;
        }
       
        @include bp-min(lg) {
            thead th:first-child {
                padding-left: 8px !important;
            }
    
            thead th:last-child {
                padding-right: 8px !important;
            }

            tbody {
                border-radius: 10px;
                box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14);

                tr:last-child td {
                    border-bottom: none;
                }
                
                td {
                    padding: 8px;
                    height: 200px;
                    border-radius: 0px;
                    border-bottom: 1px solid #E9EBEF;
    
                    &:not(:last-child) {
                        border-right: 1px solid #E9EBEF;
                    }
                }

                tr {
                    box-shadow: none !important;
                }

                tr:first-child td:first-child {
                    border-top-left-radius: 10px;
                }
    
                tr:first-child td:last-child {
                    border-top-right-radius: 10px;
                }
    
                tr:last-child td:first-child {
                    border-bottom-left-radius: 10px;
                }
    
                tr:last-child td:last-child {
                    border-bottom-right-radius: 10px;
                }
            }
        }

        @include bp-max(md) {
            thead {
                display: none;
            }

            tbody {
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 15px;

                tr {
                    padding: 0 !important;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-row-gap: 15px;
                    box-shadow: none !important;
                    background: transparent;
                    
                    td {
                        height: auto;
                        padding: 10px 15px !important;
                        border-radius: 10px !important;
                        box-shadow: 0px 0px 6px 0px rgba(168, 168, 168, 0.14) !important;
                        overflow: hidden;

                        &::before {
                            display: none !important;
                        }

                        &._not-this-month {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .day {
        height: 100%;
        
        &__header {
            position: relative;
            padding: 4px 4px 10px;

            &-date {
                color: $c-text_dark-grey-2;
                font-family: $ff-second;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
            }

            &-menu-btn {
                position: absolute;
                top: -1px;
                right: -4px;
                color: #999999;
                width: 30px;
                height: 30px;
                line-height: 30px;
                opacity: 0;
                transition: opacity 0.1s ease-in-out;

                .material-icons {
                    font-size: 22px !important;
                }
            }

            &-staff {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 8px;

                &__img {
                    min-width: 34px;
                    min-height: 34px;
                    max-width: 34px;
                    max-height: 34px;
                    width: 34px;
                    height: 34px;
                }
            }
        }

        &:hover .day__header-menu-btn {
            opacity: 1;
        }


        &__showAll {
            width: 100%;
            padding: 0 16px;
            font-family: $ff-base;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px !important;
        }

        @include bp-max(md) {
            &__header {
                padding: 0px;
                cursor: pointer;

                &-date {
                    font-size: 12px;
                    margin-bottom: 10px;

                    &::after {
                        content: attr(data-day);
                        display: block;
                        padding-top: 6px;
                        color: #999999;
                        font-family: $ff-second;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: keep-all;
                        white-space: nowrap;
                    }

                }

                &-open-btn {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 11px;
                    transition: all 0.2s ease-in-out;
                }
            }
        }

        @include bp-max(sm) {
            &__header {
                &-staff {
                    &__img {
                        min-width: 28px;
                        min-height: 28px;
                        max-width: 28px;
                        max-height: 28px;
                        width: 28px;
                        height: 28px;
                    }
                }
            }
        }
    }
}