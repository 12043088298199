// Angular Material tooltip
.mat-tooltip {
    font-family: $ff-second;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-line;
    background-color: color-mix(in srgb, $c-black 95%, transparent);

    &.tableTooltip {
        margin-bottom: -5px !important;

        &.mat-tooltip-handset {
            margin-bottom: 5px !important;
        }
    }

    &.fileTooltip {
        word-break: break-all;
        margin-top: 6px !important;

        z-index: 1100;
    }
}

// Angular Material paginator
.mat-paginator {
    background-color: transparent;
    color: $c-text_dark-grey-2;

    .mat-paginator-range-actions {
        .mat-paginator-range-label {
            font-family: $ff-base;
            margin-left: 0;
        }

        @include bp-max (sm) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;

            .mat-paginator-range-label {
                width: 100%;
                margin: 10px 0 0;
                text-align: center;
            }
        }
    }
}

// Angular Material menu
.mat-menu-panel {
    border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;

    &:not(.m0) {
        margin: 10px 0;
    }

    .mat-menu-item {
        color: $c-text_dark-grey-2 !important;
        font-family: $ff-base;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        transition: all 0.1s ease-in-out;

        &[disabled] {
            opacity: 0.38;
        }

        &.matMenu__item {
            display: flex;
            align-items: center;

            .matMenu__item-icon {
                margin-right: 14px;
                font-size: 16px;
                color: $c-text_dark-grey-2 !important;

                &:before {
                    color: $c-text_dark-grey-2 !important;
                }

                &[class^="material-icons-"], &[class*=" material-icons-"], &.material-icons {
                    margin-left: -2px;
                    margin-right: 12px;
                    font-size: 20px;
                }
            }
        }

        > span:first-child {
            position: relative;
            z-index: 1;
        }
    }

    @include bp-max(md) {
        width: 100% !important;
        max-width: 100% !important;
        margin: 10px !important;
    }

    @include bp-max(sm) {
        .mat-menu-item {
            font-size: 14px;

            &.matMenu__item .matMenu__item-icon {
                margin-right: 10px;
                font-size: 14px;

                &[class^="material-icons-"], &[class*=" material-icons-"], &.material-icons {
                    margin-right: 8px;
                    font-size: 18px;
                }
            }
        }
    }
}

@include bp-max(md) {
    .menuBackdrop, .menuBackdrop_small {
        background: #0000007A !important;

        + .cdk-overlay-connected-position-bounding-box {
            right: 0px !important;
            left: 0px !important;
            bottom: 0px !important;
            top: 0px !important;
            height: 100% !important;
            width: 100% !important;
            justify-content: flex-end !important;
            align-items: center !important;

            .cdk-overlay-pane {
                position: static !important;
                width: 100% !important;
            }
        }
    }

    .menuBackdrop {
        + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
            max-width: 500px !important;
        }
    }

    .menuBackdrop_small {
        + .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
            max-width: 300px !important;
        }
    }
}