
//  ===  global topbar style  === 
.topbar {
    position: sticky;
    display: flex;
    top: 0;
    right: 0;
    width: calc(100vw - $sidebarHiddenWidth);
    height: calc($topbarHeight);
    background: $white;
    box-shadow: 0px 1px 0px 0px #E3E4E8;
    user-select: none;
    z-index: 130;
    // transition: .3s width ease-in-out;

    &._setup {
        width: calc(100vw - $sidebarHiddenWidthSetup);
    }
    
    &.navShown {
        width: calc(100vw - $sidebarWidth);
    }

    @include bp-max(md) {
        width: 100vw !important;
        height: $topbarHeightMobil;
        background: $sidebarColor;
        box-shadow: none;

        &-profile  {
            display: none !important;
        }
    }

    &-left {
        display: flex;
        align-items: center;
        overflow: hidden;

        &__back {
            display: flex;
            justify-content: center;
            align-items: center;
            
            &-btn {
                width: 46px !important;
                height: 46px !important;
                line-height: 46px !important;
    
                .mat-button-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            
            @include bp-min(lg) {
                flex-basis: 60px;
                width: 60px;
                height: 100%;
                border-right: 1px solid #E3E4E8 !important;

                &-btn {
                    width: 50px !important;
                    height: 50px !important;
                    line-height: 50px !important;
                }
            }

            @include bp-max(md) {
                flex-basis: 46px;
                margin-left: 15px;

                &-btn {
                    font-size: 20px;
                }
            }
        }

        &__menu {
            position: relative;
            flex-basis: 46px;
            width: 46px !important;
            height: 46px !important;
            margin-left: 15px !important;
            line-height: 46px !important;

            .mat-button-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &._ntf::after, &._timer::after {
                content: "";
                position: absolute;
                right: 5px;
                top: 11px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                opacity: 1;
                transition: .2s all ease-in-out;
            }

            &._ntf._close::after, &._timer._close::after {
                opacity: 0;
            }

            &._ntf:not(._timer)::after {
                background-color: $c-main;
            }

            &._timer::after {
                background-color: $c-red;
            }

            @include bp-min(lg) {
                display: none !important;
            }
        }
        
        &__page {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 30px;
            font-family: $ff-third;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            overflow: hidden;

            @include bp-max(md) {
                margin: 10px;
                margin-right: 20px;
            }

            @include bp-max(sm) {
                margin-right: 0;
            }
    
            &__title {
                color: $c-text_dark-grey-2;
                font-size: 20px;

                @include bp-max(md) {
                    // font-size: 16px;
                    color: $white;
                }
            }
    
            &__subtitle {
                color: #C8CDD9;
                font-size: 12px;

                @include bp-max(md) {
                    // font-size: 10px;
                }
            }
        }
    }
    
    &-right {
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        padding-right: 25px;
    }

    &-logo {
        width: 32px;
        height: 32px;
        margin-left: 15px;
        flex-shrink: 0;

        @include bp-min(lg) {
            display: none !important;
        }
    }

    &-profile  {
        height: 100%;
        cursor: pointer;

        &__info {
            max-width: 200px;
    
            @include bp-max(lg) {
                max-width: 90px;
            }
        }
    }
}

.topbar-user-btn {
    height: 42px!important;
    padding: 10px 20px!important;
    border-width: 2px !important;
    background-color: transparent !important;
    border-radius: 10px !important;
    font-size: 16px !important;

    .icon-plus {
        margin-right: 10px;
    }
}

.topbar-btn {
    width: 42px !important;
    height: 42px !important;
    margin-left: 3px !important;
    line-height: 42px !important;

    &__icon {
        color: #D4D4D4;

        @include bp-max(md) {
            color: $white;
        }
    }

    &._primary &__icon {
        color: $c-main;
    }

    &._warn &__icon {
        color: $c-red;
    }
}

.popup {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.09);
    box-shadow: $shadow-popup;
    background-color: white;

    @include bp-min(lg) {
        > * {
            animation: 0.15s opacityIn;
        }
        
        @keyframes opacityIn {
            0% {
                opacity: 0;
            }
            
            70% {
                opacity: 0;
            }
        
            100% {
                opacity: 1;
            }
        }
    }
}

.user-popup {
    width: 270px;
    padding-top: 15px;
    margin-top: calc( 10px + 16px );
}

.profile-popup {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-top: 10px;
    overflow: hidden;

    &__item {
        height: 42px !important;
        border-radius: 0 !important;
        color: $c-black !important;
        font-family: $ff-base;
        font-size: 16px;
        font-weight: 400;
        line-height: 42px !important;

        &._warn {
            color: $c-red !important;
        }
    }
}

.ntf-popup {
    margin-top: calc( ( $topbarHeight - 42px ) / 2 + 10px);
    min-width: 200px;

    &__head {
        padding: 10px 10px 10px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include bp-max(sm) {
            padding: 5px 5px 5px 15px;
        }
        
        &__title {
            color: $c-text_dark-grey-2;
            font-family: $ff-third;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;

            @include bp-max(sm) {
                font-size: 16px;
            }
        }
    
        &__stgs {
            &-icon, &-icon::before {
                width: 20px;
                height: 20px;
                color: #D4D4D4 !important;
                font-size: 20px;
            }
        }
    }

    &__body {
        padding: 0px 25px 10px;
        overflow-y: auto;
        max-height: calc(100vh - $topbarHeight * 2 - 62px);

        @include bp-max(sm) {
            padding: 0px 15px 5px;
        }
    }

    &__item {
        border-bottom: 1px solid $c-dark_white;
        // opacity: 0;
        // transform: translateY(-20px);
        transition: opacity 0.2s, transform 0.2s;
        // animation: fadeIn 0.5s calc(0.5s * 1) forwards;

        @keyframes fadeIn {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    
        &:last-child {
            border-bottom: none;
        }
    }

    &._active {
        transform: translateY(0);
        transition: .5s all ease-in-out;
    }

        
    .ntf {
        @include bp-max(xs) {
            width: 220px !important;
        }
    }

    .empty-list {
        display: flex;
        flex-direction: column;
        width: 300px;
        padding: 15px 25px 25px;
        text-align: center;
        user-select: none;

        @include bp-max(sm) {
            padding: 15px;
        }

        @include bp-max(xs) {
            width: 220px;
        }

        &__icon {
            margin-bottom: 10px;
            color: $c-dark_white;
            font-size: 40px;
        }

        &__text {
            color: #A4A6AA;
            font-family: $ff-base;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            @include bp-max(sm) {
                font-size: 12px;
            }
        }
    }
}

.topbar-profile, .nav-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &__img {
        flex-shrink: 0;
        position: relative;
        width: 52px;
        height: 52px;
        border: 1px solid #F4F4F4;
        box-sizing: border-box;
        border-radius: 50%;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: "";
            position: absolute;
            top: 3px;
            right: 2px;
            width: 8px;
            height: 8px;
            background-color: $c-main;
            border-radius: 50%;
        }

        picture {
            display: block;
            width: 40px !important;
            height: 40px !important;
            min-height: 40px !important;
            min-width: 40px !important;
            max-height: 40px !important;
            max-width: 40px !important;
        }

        img {
            width: 40px !important;
            height: 40px !important;
            border-radius: 50%;
            object-fit: cover;
            border: 1px solid #000;
        }
    }

    &__info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 26px;
        overflow: hidden;

        @include bp-max(xs) {
            margin-left: 15px;
        }

        &-name {
            color: $c-black;
            font-family: $ff-base;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include bp-max(md) {
                color: $white;
            }
        }

        &-role {
            color: #A4A6AA;
            font-family: $ff-base;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    
    &__btn {
        width: 42px !important;
        height: 42px !important;
        line-height: 42px !important;
        font-size: 12px !important;
        margin-left: 3px !important;
    }
}

.topbar-profile {
    margin-left: 10px;
}

.topbar-date_and_time {
    transition: all 0.15s ease-in-out 0s;

    &::after, .topbar-date {
        margin-right: 16px;  
        font-family: $ff-base;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        white-space: nowrap;
        color: #BDBDBD; 
    }
    
    .topbar-time {
        margin-right: 10px;
        font-family: $ff-base;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        white-space: nowrap;
        color: #999999;
    }

    @include bp-max(lg) {
        &._hide {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
    
            &::after {
                margin-right: 10px;  
                content: attr(data-date);
            }
            
            .topbar-date {
                display: none;
            }
        }
    }
    @media screen and (max-width: 1170px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;

        .topbar-date {
            margin-right: 10px;  
        }

        &._hide {
            display: none;
        }
    }
}